<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay :show="show" rounded="sm">
      <!-- PERMISSION TABLE -->
      <b-card no-body class="border mt-1">
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon icon="LockIcon" size="18" />
            <span class="align-middle ml-50">{{ $t('Permission') }}</span>
          </b-card-title>
        </b-card-header>
        <div class="px-2">
          <b-form-group :label="$t('Name')" label-for="register-name">
            <b-form-input id="register-name" v-model="name" name="register-name" :placeholder="$t('Name')" />
          </b-form-group>
        </div>
        <b-table striped responsive class="mb-0" :items="permissionsData" :fields="tableColumns" style="height: 60vh;">
          <template #cell(module)="data">
            {{ data.item.module }}
          </template>
          <template #cell(read)="data">
            <!-- <b-form-checkbox
                v-model="permissionsData[data.index].read"
                name="check-button"
                switch
                inline
              > -->
            <b-form-checkbox v-model="permissionsData[data.index].read"
              @change="clickCheck($event, data.index, 'read')" />
          </template>
          <template #cell(update)="data">
            <b-form-checkbox v-model="permissionsData[data.index].update"
              @change="clickCheck($event, data.index, 'update')" />
          </template>
          <template #cell(create)="data">
            <b-form-checkbox v-model="permissionsData[data.index].create"
              @change="clickCheck($event, data.index, 'create')" />
          </template>
          <template #cell(delete)="data">
            <b-form-checkbox v-model="permissionsData[data.index].delete"
              @change="clickCheck($event, data.index, 'delete')" />
          </template>
        </b-table>
      </b-card>
    </b-overlay>
    <!-- Action Buttons -->
    <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="submit()">
      {{ $t('Save Changes') }}
    </b-button>
    <b-button variant="outline-secondary" type="reset" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
      {{ $t('Reset') }}
    </b-button>
  </div>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  BButton,
  BOverlay,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import storeModule from '@/store/services/eCommerce/permissionInApp'

const STORE_MODULE_NAME = 'permissionInApp'

export default {
  components: {
    BButton,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    BMedia,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  data() {
    return {
      show: false,
      dataId: null,
      name: '',
      //   permissionsData: [
      //   ],
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'module', label: this.$t('Module'), sortable: false },
        { key: 'read', label: this.$t('Read'), sortable: false },
        { key: 'update', label: this.$t('Update'), sortable: false },
        { key: 'create', label: this.$t('Create'), sortable: false },
        { key: 'delete', label: this.$t('Delete'), sortable: false },
      ]
    },
    permissionsData() {
      return store.state[STORE_MODULE_NAME].respPermissionData != null
        ? store.state[STORE_MODULE_NAME].respPermissionData
        : []
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/getPermission`, {})
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        //
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        if (this.$route.params.id !== 'add') {
          this.dataId = this.$route.params.id
          this.viewPermission(this.$route.params.id)
        }

        // eslint-disable-next-line no-unused-vars
      })
      .catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    clickCheck(event, index, type) {
      if (type === 'read') {
        this.permissionsData[index].read = event
      } else if (type === 'update') {
        this.permissionsData[index].update = event
      } else if (type === 'create') {
        this.permissionsData[index].create = event
      } else if (type === 'delete') {
        this.permissionsData[index].delete = event
      }
    },
    viewPermission(id) {
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/view`, id)
        .then(result => {
          this.show = false
          console.log('fetchUsers Success : ', result)
          this.name = result.data.data.name
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    submit() {
      if (this.dataId === null) {
        this.addPermission()
      } else {
        this.updatePermission()
      }
    },
    addPermission() {
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/add`, {
          name: this.name,
          permissionsData: this.permissionsData,
        })
        .then(result => {
          this.show = false

          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'CheckIcon',
              variant: 'success',
              text: this.$t('Success'),
            },
          })
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    updatePermission() {
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/update`, {
          id: this.dataId,
          name: this.name,
          permissionsData: this.permissionsData,
        })
        .then(result => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'CheckIcon',
              variant: 'success',
              text: this.$t('Success'),
            },
          })
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
